// app/javascript/controllers/wedding_guest_form_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "conditionalFields", "partnerInput", "partnerList", "addButton"]

  connect() {
    const formInputs = document.querySelectorAll('.form-focus')
    formInputs.forEach(input => {
      input.addEventListener('focus', this.handleFocusBlur.bind(this))
      input.addEventListener('blur', this.handleFocusBlur.bind(this))
    })

    this.formTarget.addEventListener('submit', this.handleSubmit.bind(this))

    // Add event listeners to the invitation acceptance radio buttons
    document.querySelector('#accept_invitation_yes').addEventListener('change', this.toggleConditionalFields.bind(this))
    document.querySelector('#accept_invitation_no').addEventListener('change', this.toggleConditionalFields.bind(this))

    this.addButtonTarget.addEventListener('click', this.addPartner.bind(this));
    this.partnerListTarget.addEventListener('click', this.removePartner.bind(this));
  }

  handleFocusBlur(event) {
    const label = event.target.nextElementSibling
    if (event.target.value === '') {
      if (event.type === 'focus') {
        label.classList.add('filled')
      } else if (event.type === 'blur') {
        label.classList.remove('filled')
      }
    }
  }

  toggleConditionalFields() {
    const conditionalFields = document.querySelectorAll('.conditional-field')
    if (document.querySelector('#accept_invitation_no').checked) {
      this.clearForm()

      conditionalFields.forEach(field => {
        if (!field.classList.contains('always-visible')) {
          field.style.display = 'none'
        }
      })
    } else {
      conditionalFields.forEach(field => field.style.display = 'block')
    }
  }

  addPartner(event) {
    event.preventDefault();
    const partnerVal = this.partnerInputTarget.value.trim();
  
    if (!partnerVal) {
      this.partnerInputTarget.focus();
      return;
    }
  
    // Crea un contenitore per l'elemento del partner
    const partnerDiv = document.createElement('div');
    partnerDiv.innerHTML = `
      <input class="form-control" type="text" value="${partnerVal}" readonly />
      <a href="#" class="remove_field">
        <i class="fa fa-trash" aria-hidden="true"></i>
      </a>
    `;
  
    // Aggiungi il contenitore alla lista
    this.partnerListTarget.appendChild(partnerDiv);
  
    // Pulisce il campo di input
    this.partnerInputTarget.value = '';
  }

  removePartner(event) {
    const removeIcon = event.target.closest('.remove_field');
    if (removeIcon) {
      event.preventDefault();
      // Rimuove il contenitore del partner (il div padre)
      removeIcon.parentElement.remove();
    }
  }

  clearForm() {
    // Clear specific input fields
    document.getElementById('form-phone').value = ''
    document.getElementById('form-notes').value = ''
    document.querySelectorAll('input[name="need_bus"]').forEach(input => input.checked = false)

    // Clear partner list
    this.partnerListTarget.innerHTML = ''
  }

  async handleSubmit(event) {
    event.preventDefault()

    // Remove the error class and previous messages
    document.querySelectorAll('.form-group').forEach(group => group.classList.remove('has-error'))
    document.querySelectorAll('.help-block').forEach(block => block.remove())
    const messageBox = document.getElementById('message-box')
    messageBox.innerHTML = ''

    const partnerList = Array.from(document.querySelectorAll('.partner-list input')).map(input => input.value)

    // Get the Turnstile token
    const turnstileToken = document.querySelector('.cf-turnstile input[name="cf-turnstile-response"]').value

    // Get the form data
    const formData = {
      name: document.getElementById('form-name').value,
      surname: document.getElementById('form-surname').value,
      phone: document.getElementById('form-phone').value,
      invitation_accepted: document.querySelector('#accept_invitation_yes').checked ? true : (document.querySelector('#accept_invitation_no').checked ? false : undefined),
      need_bus: document.querySelector('#need_bus_yes').checked ? true : (document.querySelector('#need_bus_no').checked ? false : undefined),
      partners_list: partnerList.join(', '),
      notes: document.getElementById('form-notes').value,
      invitation_code: document.getElementById('form-invitation-code').value,
      cloudflare_response_token: turnstileToken
    }

    // Check if required fields are filled
    let hasError = false

    if (!formData.name) {
      const nameField = document.getElementById('name-field')
      nameField.classList.add('has-error')
      messageBox.insertAdjacentHTML('beforeend', `<div class="alert alert-danger">Per favore inserisci il tuo nome.</div>`)
      hasError = true
    }

    if (!formData.surname) {
      const surnameField = document.getElementById('surname-field')
      surnameField.classList.add('has-error')
      messageBox.insertAdjacentHTML('beforeend', `<div class="alert alert-danger">Per favore inserisci il tuo cognome.</div>`)
      hasError = true
    }

    if (formData.invitation_accepted && !formData.phone) {
      const phoneField = document.getElementById('phone-field')
      phoneField.classList.add('has-error')
      messageBox.insertAdjacentHTML('beforeend', `<div class="alert alert-danger">Per favore inserisci il tuo numero di telefono.</div>`)
      hasError = true
    }

    if (formData.invitation_accepted === undefined) {
      const acceptanceField = document.getElementById('acceptance-field')
      acceptanceField.classList.add('has-error')
      messageBox.insertAdjacentHTML('beforeend', `<div class="alert alert-danger">Per favore scegli se parteciperai al matrimonio.</div>`)
      hasError = true
    }

    if (formData.invitation_accepted && formData.need_bus === undefined) {
      const busField = document.getElementById('bus-field')
      busField.classList.add('has-error')
      messageBox.insertAdjacentHTML('beforeend', `<div class="alert alert-danger">Per favore scegli se avrai bisogno del bus.</div>`)
      hasError = true
    }

    if (!formData.invitation_code) {
      const invitationCodeField = document.getElementById('invitation-code-field')
      invitationCodeField.classList.add('has-error')
      messageBox.insertAdjacentHTML('beforeend', `<div class="alert alert-danger">Per favore inserisci il codice invito.</div>`)
      hasError = true
    }

    if (hasError) return

    try {
      const response = await fetch('/wedding_guests', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify(formData)
      })

      const data = await response.json()

      if (!data.success) {
        if (data.errors) {
          Object.keys(data.errors).forEach(field => {
            const fieldElement = document.getElementById(`${field}-field`)
            if (fieldElement) {
              fieldElement.classList.add('has-error')
              fieldElement.querySelector('.col-sm-6').insertAdjacentHTML('beforeend', `<span class="help-block">${data.errors[field]}</span>`)
            }
            messageBox.insertAdjacentHTML('beforeend', `<div class="alert alert-danger">${data.errors[field]}</div>`)
          })

          turnstile.reset()
        }
      } else {
        messageBox.insertAdjacentHTML('beforeend', `<div class="alert alert-success">${data.message}</div>`)
        this.formTarget.querySelector('input[type="submit"]').style.display = 'none' // Hide the submit button
      }
    } catch (error) {
      console.error('Error:', error)
      messageBox.insertAdjacentHTML('beforeend', `<div class="alert alert-danger">An error occurred. Please try again later.</div>`)

      turnstile.reset()
    }
  }
}